:root,
[data-bs-theme=light] {
  --bs-link-color-rgb: #1D1D1D;
  --bs-link-hover-color-rgb: #1D1D1D;
}

.form-section {
  padding: 16px 0;
}
.form-section .section-title {
  font-size: 28px;
  margin-bottom: 32px;
}
.form-section .form-holder {
  padding: 40px;
  background-color: #fff;
}
@media (min-width: 768px) {
  .form-section {
    padding: 130px 0;
    padding: 64px 0;
  }
  .form-section .form-holder {
    border-radius: 20px;
  }
}
@media (max-width: 767.98px) {
  .form-section .container {
    padding-left: 0;
    padding-right: 0;
  }
}

.contact-form .wpcf7 .wpcf7-form-control-wrap {
  display: block;
}
.contact-form .wpcf7 .custom-checkboxes {
  margin: 4px 0;
}
.contact-form .wpcf7 .custom-checkboxes .wpcf7-list-item-label::before {
  border-radius: 8px;
}
.contact-form .wpcf7 .custom-checkboxes [type=checkbox]:checked ~ .wpcf7-list-item-label {
  color: #005666;
}
.contact-form .wpcf7 .custom-checkboxes [type=checkbox]:checked ~ .wpcf7-list-item-label::after {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='12' height='9' viewBox='0 0 12 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M10.6667 1.5L4.25004 7.91667L1.33337 5' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3e%3c/svg%3e ");
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23005666' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23005666' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e") 50%/50% 50% no-repeat;
}
.contact-form .wpcf7 .custom-checkboxes [type=checkbox]:indeterminate ~ .wpcf7-list-item-label::before {
  border-color: #005666;
  background-color: #fff;
}
.contact-form .wpcf7 .custom-checkboxes [type=checkbox]:indeterminate ~ .wpcf7-list-item-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23005666' d='M0 2h4'/%3e%3c/svg%3e");
}
.contact-form .wpcf7 .custom-checkboxes [type=checkbox]:disabled:checked ~ .wpcf7-list-item-label::before {
  background-color: rgba(144, 144, 144, 0.5);
  border-color: rgba(0, 86, 102, 0.4);
}
.contact-form .wpcf7 .custom-checkboxes [type=checkbox]:disabled:indeterminate ~ .wpcf7-list-item-label::before {
  background-color: rgba(144, 144, 144, 0.5);
}
.contact-form .wpcf7 .custom-radiobuttons {
  margin: 4px 0;
}
.contact-form .wpcf7 .custom-radiobuttons .wpcf7-list-item-label::before {
  border-radius: 50%;
}
.contact-form .wpcf7 .custom-radiobuttons [type=radio]:checked ~ .wpcf7-list-item-label {
  color: #005666;
}
.contact-form .wpcf7 .custom-radiobuttons [type=radio]:checked ~ .wpcf7-list-item-label::after {
  background: url("data:image/svg+xml,%3Csvg width='24' height='24' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Ccircle cx='12' cy='12' r='12' fill='%23005666'/%3E%3C/svg%3E") 50%/50% 50% no-repeat;
}
.contact-form .wpcf7 .custom-radiobuttons [type=radio]:disabled:checked ~ .wpcf7-list-item-label::before {
  background-color: rgba(144, 144, 144, 0.5);
  border-color: rgba(0, 86, 102, 0.4);
}
.contact-form .wpcf7 .wpcf7-list-item {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.5rem;
  margin-bottom: 1.125rem;
  margin-left: 0;
  margin-right: 1rem;
}
.contact-form .wpcf7 .wpcf7-list-item:hover [type=radio]:not(:disabled) ~ .wpcf7-list-item-label::before,
.contact-form .wpcf7 .wpcf7-list-item:hover [type=checkbox]:not(:disabled) ~ .wpcf7-list-item-label::before {
  box-shadow: 0 0 0 6px #00CCCC;
  background-color: #00CCCC;
}
.contact-form .wpcf7 .wpcf7-list-item .wpcf7-list-item-label {
  position: relative;
  display: inline-block;
  padding-left: 2.5rem;
  margin-bottom: 0;
  vertical-align: top;
}
.contact-form .wpcf7 .wpcf7-list-item .wpcf7-list-item-label::before {
  position: absolute;
  top: 0rem;
  left: 0;
  display: block;
  width: 1.5rem;
  height: 1.5rem;
  pointer-events: none;
  content: "";
  background-color: transparent;
  border: #005666 solid 2px;
  transition: box-shadow 0.1s ease-in-out;
}
.contact-form .wpcf7 .wpcf7-list-item .wpcf7-list-item-label::after {
  position: absolute;
  top: 0rem;
  left: 0;
  display: block;
  width: 1.5rem;
  height: 1.5rem;
  content: "";
  background: 50%/50% 50% no-repeat;
}
.contact-form .wpcf7 .wpcf7-list-item [type=radio],
.contact-form .wpcf7 .wpcf7-list-item [type=checkbox] {
  position: absolute;
  left: 0;
  z-index: 1;
  width: 1.5rem;
  height: 1.5rem;
  opacity: 0;
}
.contact-form .wpcf7 .wpcf7-list-item [type=radio]:checked ~ .wpcf7-list-item-label::before,
.contact-form .wpcf7 .wpcf7-list-item [type=checkbox]:checked ~ .wpcf7-list-item-label::before {
  color: #005666;
  border-color: #005666;
}
.contact-form .wpcf7 .wpcf7-list-item [type=radio]:focus:not(:checked) ~ .wpcf7-list-item-label::before,
.contact-form .wpcf7 .wpcf7-list-item [type=checkbox]:focus:not(:checked) ~ .wpcf7-list-item-label::before {
  border-color: #005666;
}
.contact-form .wpcf7 .wpcf7-list-item [type=radio]:not(:disabled):active ~ .wpcf7-list-item-label::before,
.contact-form .wpcf7 .wpcf7-list-item [type=checkbox]:not(:disabled):active ~ .wpcf7-list-item-label::before {
  color: #fff;
  background-color: rgba(0, 86, 102, 0.25);
  border-color: rgba(0, 86, 102, 0.25);
}
.contact-form .wpcf7 .wpcf7-list-item [type=radio][disabled] ~ .wpcf7-list-item-label, .contact-form .wpcf7 .wpcf7-list-item [type=radio]:disabled ~ .wpcf7-list-item-label,
.contact-form .wpcf7 .wpcf7-list-item [type=checkbox][disabled] ~ .wpcf7-list-item-label,
.contact-form .wpcf7 .wpcf7-list-item [type=checkbox]:disabled ~ .wpcf7-list-item-label {
  color: #909090;
}
.contact-form .wpcf7 .wpcf7-list-item [type=radio][disabled] ~ .wpcf7-list-item-label::before, .contact-form .wpcf7 .wpcf7-list-item [type=radio]:disabled ~ .wpcf7-list-item-label::before,
.contact-form .wpcf7 .wpcf7-list-item [type=checkbox][disabled] ~ .wpcf7-list-item-label::before,
.contact-form .wpcf7 .wpcf7-list-item [type=checkbox]:disabled ~ .wpcf7-list-item-label::before {
  background-color: #D1D1D1;
}
.contact-form .wpcf7 .wpcf7-list-item [type=radio]:not(:disabled):focus-visible ~ .wpcf7-list-item-label::before,
.contact-form .wpcf7 .wpcf7-list-item [type=checkbox]:not(:disabled):focus-visible ~ .wpcf7-list-item-label::before {
  box-shadow: 0 0 0 2px #F2F2F2, 0 0 0 4px #005666;
}
.contact-form .wpcf7 .form-select {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  background-color: #D9D9D9;
  border-radius: 3rem;
}
.contact-form .wpcf7 .form-group-btn {
  position: relative;
  margin-top: 2rem;
  margin-bottom: 0;
}
.contact-form .wpcf7 .btn-submit {
  color: #1D1D1D;
  background-color: #00CCCC;
  border-color: #00CCCC;
  min-width: 168px;
}
.contact-form .wpcf7 .btn-submit:hover {
  color: #fff;
  background-color: #1D1D1D;
  border-color: #1D1D1D;
}
.contact-form .wpcf7 .wpcf7-not-valid-tip {
  margin-top: 4px;
}
@media (min-width: 576px) {
  .contact-form .wpcf7 .custom-radiobuttons,
  .contact-form .wpcf7 .custom-checkboxes {
    display: flex;
    flex-wrap: wrap;
  }
  .contact-form .wpcf7 .custom-radiobuttons .wpcf7-list-item,
  .contact-form .wpcf7 .custom-checkboxes .wpcf7-list-item {
    width: 25%;
    min-width: 170px;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .contact-form .wpcf7 .form-group-half-width {
    width: 50%;
    padding-right: 16px;
  }
}
@media (min-width: 768px) {
  .contact-form .wpcf7 .custom-radiobuttons,
  .contact-form .wpcf7 .custom-checkboxes {
    margin-top: -10px;
    margin-bottom: -10px;
  }
}

.wpcf7-form.submitting .btn-submit {
  opacity: 0.4;
}

/* Custom CF7 Spinner */
div.wpcf7 .wpcf7-spinner {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  opacity: 0.8;
}
@supports (-webkit-appearance:none){
.contact-form .wpcf7 .custom-checkboxes [type=checkbox]:checked ~ .wpcf7-list-item-label::after{background-image:url("data:image/svg+xml;charset=UTF-8,%3csvg width='12' height='9' viewBox='0 0 12 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M10.6667 1.5L4.25004 7.91667L1.33337 5' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3e%3c/svg%3e ")}
.contact-form .wpcf7 .custom-checkboxes [type=checkbox]:checked ~ .wpcf7-list-item-label::after{background-image:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23005666' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e")}
.contact-form .wpcf7 .custom-checkboxes [type=checkbox]:checked ~ .wpcf7-list-item-label::after{background:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23005666' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e") 50%/50% 50% no-repeat}
.contact-form .wpcf7 .custom-checkboxes [type=checkbox]:indeterminate ~ .wpcf7-list-item-label::after{background-image:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23005666' d='M0 2h4'/%3e%3c/svg%3e")}
.contact-form .wpcf7 .custom-radiobuttons [type=radio]:checked ~ .wpcf7-list-item-label::after{background:url("data:image/svg+xml,%3Csvg width='24' height='24' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Ccircle cx='12' cy='12' r='12' fill='%23005666'/%3E%3C/svg%3E") 50%/50% 50% no-repeat}
}

 @media (min-width: 768px){

@supports (-webkit-appearance:none){

}

}

 @media (max-width: 767.98px){

@supports (-webkit-appearance:none){

}

}

 @media (min-width: 576px){

@supports (-webkit-appearance:none){

}

}
