@import '../../node_modules/bootstrap/scss/functions';
@import '../../node_modules/bootstrap/scss/variables';
@import '../../node_modules/bootstrap/scss/variables-dark';
@import '../../node_modules/bootstrap/scss/mixins';
@import '../../node_modules/bootstrap/scss/maps';

@import '../../src/scss/components/vars';
@import '../../src/scss/components/mixins';

.form-section {
  padding: 16px 0;
  .section-title {
    font-size: 28px;
    margin-bottom: 32px;
  }
  .form-holder {
    padding: 40px;
    background-color: $white;
  }
  @include media-breakpoint-up(md) {
    padding: 130px 0;
    padding: $margin-lg 0;
    .members-sorting-section + & {
      //margin-top: -64px;
    }
    .form-holder {
      border-radius: $radius-xl;
    }
  }
  @include media-breakpoint-down(md) {
    .container {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

// CF7
.contact-form {
  .wpcf7 {
    .wpcf7-form-control-wrap {
      display: block;
    }

    .custom-checkboxes {
      margin: 4px 0;
      .wpcf7-list-item-label::before {
        @include border-radius($custom-checkbox-indicator-border-radius);
      }

      [type="checkbox"]:checked ~ .wpcf7-list-item-label {
        color: $primary-dark;
        &::after {
          background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='12' height='9' viewBox='0 0 12 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M10.6667 1.5L4.25004 7.91667L1.33337 5' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3e%3c/svg%3e ");
          background-image: escape-svg($custom-checkbox-indicator-icon-checked);
          background: escape-svg($custom-checkbox-indicator-icon-checked) 50% / #{$custom-control-indicator-bg-size} no-repeat;;
        }
      }

      [type="checkbox"]:indeterminate ~ .wpcf7-list-item-label {
        &::before {
          border-color: $custom-checkbox-indicator-indeterminate-border-color;
          @include gradient-bg($custom-checkbox-indicator-indeterminate-bg);
          @include box-shadow($custom-checkbox-indicator-indeterminate-box-shadow);
        }
        &::after {
          background-image: escape-svg($custom-checkbox-indicator-icon-indeterminate);
        }
      }

      [type="checkbox"]:disabled {
        &:checked ~ .wpcf7-list-item-label::before {
          @include gradient-bg($custom-control-indicator-checked-disabled-bg);
          border-color: rgba($primary-dark,.4);
        }
        &:indeterminate ~ .wpcf7-list-item-label::before {
          @include gradient-bg($custom-control-indicator-checked-disabled-bg);
        }
      }
    }
    // radio
    .custom-radiobuttons {
      margin: 4px 0;
      .wpcf7-list-item-label::before {
        // stylelint-disable-next-line property-disallowed-list
        border-radius: $custom-radio-indicator-border-radius;
      }

      [type="radio"]:checked ~ .wpcf7-list-item-label {
        color: $primary-dark;
        &::after {
          //background-image: escape-svg($custom-radio-indicator-icon-checked);
          background: escape-svg($custom-radio-indicator-icon-checked) 50% / #{$custom-control-indicator-bg-size} no-repeat;
          //background-color: transparent;
        }
      }

      [type="radio"]:disabled {
        &:checked ~ .wpcf7-list-item-label::before {
          @include gradient-bg($custom-control-indicator-checked-disabled-bg);
          border-color: rgba($primary-dark,.4);
        }
      }
    }
    // custom-control - checkbox or radio
    .wpcf7-list-item {
      position: relative;
      z-index: 1;
      display: block;
      min-height: $font-size-base * $line-height-base;
      //padding-left: $custom-control-gutter + $custom-control-indicator-size;
      margin-bottom: 1.125rem;
      margin-left: 0;
      margin-right: 1rem;

      &:hover {
        [type="radio"],
        [type="checkbox"] {
          &:not(:disabled) ~ .wpcf7-list-item-label {
            &::before {
              box-shadow: 0 0 0 6px $primary;
              background-color: $primary;
            }
          }
        }
      }
      .wpcf7-list-item-label {
        position: relative;
        display: inline-block;
        padding-left: $custom-control-gutter + $custom-control-indicator-size;
        margin-bottom: 0;
        color: $custom-control-label-color;
        vertical-align: top;
        cursor: $custom-control-cursor;

        // Background-color and (when enabled) gradient
        &::before {
          position: absolute;
          top: calc(($font-size-base * $line-height-base - $custom-control-indicator-size) / 2);
          left: 0;
          //left: 0;
          display: block;
          width: $custom-control-indicator-size;
          height: $custom-control-indicator-size;
          pointer-events: none;
          content: "";
          background-color: $custom-control-indicator-bg;
          border: $custom-control-indicator-border-color solid 2px;
          @include box-shadow($custom-control-indicator-box-shadow);
          transition: box-shadow $tr-anim-xs;
        }

        // Foreground (icon)
        &::after {
          position: absolute;
          top: calc(($font-size-base * $line-height-base - $custom-control-indicator-size) / 2);
          left: 0;
          //left: 0;
          display: block;
          width: $custom-control-indicator-size;
          height: $custom-control-indicator-size;
          content: "";
          background: 50% / #{$custom-control-indicator-bg-size} no-repeat;
        }
      }
      [type="radio"],
      [type="checkbox"] {
        position: absolute;
        left: 0;
        z-index: 1; // in CF z-index set to 1 to have click on checkbox, as we don't have tag labels related to inputs via ID
        width: $custom-control-indicator-size;
        height: calc(($font-size-base * $line-height-base + $custom-control-indicator-size) / 2);
        opacity: 0;

        &:checked ~ .wpcf7-list-item-label::before {
          color: $custom-control-indicator-checked-color;
          border-color: $primary-dark;
          //background-color: $primary-dark;
        }

        &:focus ~ .wpcf7-list-item-label::before {
          //box-shadow: $custom-control-indicator-focus-box-shadow;
        }

        &:focus:not(:checked) ~ .wpcf7-list-item-label::before {
          //border-color: rgba($primary-dark,.5);
          border-color: $primary-dark;
        }

        &:not(:disabled):active ~ .wpcf7-list-item-label::before {
          color: $custom-control-indicator-active-color;
          background-color: $custom-control-indicator-active-bg;
          border-color: $custom-control-indicator-active-border-color;
          @include box-shadow($custom-control-indicator-active-box-shadow);
        }

        // Use [disabled] and :disabled to work around https://github.com/twbs/bootstrap/issues/28247
        &[disabled],
        &:disabled {
          ~ .wpcf7-list-item-label {
            color: $custom-control-label-disabled-color;

            &::before {
              background-color: $custom-control-indicator-disabled-bg;
            }
          }
        }
        &:not(:disabled) {
          &:focus-visible {
            ~ .wpcf7-list-item-label {
              &::before {
                box-shadow: $focus-visible;
              }
            }
          }
        }
      }
      //[type="radio"] {
      //  ~ .wpcf7-list-item-label {
      //    &::before {
      //      border-radius: $custom-radio-indicator-border-radius;
      //    }
      //  }
      //}
    }
    .form-select {
      padding-top: .5rem;
      padding-bottom: .5rem;
      background-color: $gray-light2;
      border-radius: 3rem;
    }
    .form-group-btn {
      position: relative;
      margin-top: 2rem;
      margin-bottom: 0;
    }
    .btn-submit {
      color: $body-color;
      background-color: $primary;
      border-color: $primary;
      min-width: 168px;
      &:hover {
        color: $white;
        background-color: $gray-darker;
        border-color: $gray-darker;
      }
    }
    .wpcf7-not-valid-tip {
      margin-top: 4px;
    }
  }
  @include media-breakpoint-up(sm) {
    .wpcf7 {
      .custom-radiobuttons,
      .custom-checkboxes {
        display: flex;
        flex-wrap: wrap;

        .wpcf7-list-item {
          width: 25%;
          min-width: 170px;
          margin-top: 1rem;
          margin-bottom: 1rem;
        }
      }
      .form-group-half-width {
        width: 50%;
        padding-right: calc($grid-gutter-width/2);
      }
    }
  }
  @include media-breakpoint-up(md) {
    .wpcf7 {
      .custom-radiobuttons,
      .custom-checkboxes {
        margin-top: -10px;
        margin-bottom: -10px;
      }
      .form-group-half-width {
        //width: 50%;
        //padding-right: calc($grid-gutter-width/2);
      }
    }
  }
}

.wpcf7-form.submitting {
  .btn-submit {
    opacity: 0.4;
  }
}

/* Custom CF7 Spinner */
div.wpcf7 .wpcf7-spinner {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  //background-color: $primary;
  opacity: 0.8;
}
